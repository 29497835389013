<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="6">
							<common-page-btn @save="$refs.maintainForm._show()">
								<a-button type="primary" @click="Exportex">导出报表</a-button>
							</common-page-btn>
						</a-col>
						<a-col span="18">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="8">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getMaintainList"
										@on-clear="getMaintainList" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="5">
									<a-month-picker @clear="getMaintainList" @change="getMaintainList" v-model="param.date"
										placeholder="请选择月份" />
								</a-col>
								<a-col span="8">
									<select-car multiple v-model="param.carIds" @confirm="(e, c) => param.carIds = c"
										@success="getMaintainList()"></select-car>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-layout>
			<a-layout-content>
				<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false"
					:indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="id" border ref="list"
					:loading="loading" :data-source="data.records">
					<template slot-scope="row" slot="organId">
						<div>{{ organList.find(item => item.organId == row.organId).name }}</div>
					</template>

					<!--操作-->
					<template slot-scope="row" slot="action">
						<a-button-group shape="circle" size="small">
							<a-tooltip title="删除"><a-button type="link" v-has="'car:maintain:del'" icon="delete"
									@click="del(row.id)"></a-button></a-tooltip>
						</a-button-group>
					</template>
				</a-table>
			</a-layout-content>
		</a-layout>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
					@showSizeChange="setLimit" show-size-changer :pageSizeOptions="pageSizeOptions" :current="data.current">
					<template slot="buildOptionText" slot-scope="props">
						<span>{{ props.value }}条/页</span>
					</template>
				</a-pagination>
			</a-col>
		</a-row>
		<maintain-form ref="maintainForm" @success="doRefresh"></maintain-form>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import maintainForm from './components/maintainForm.vue';
import utils from '@/common/utils';
import selectCar from './components/selectCar.vue';
import ExporttoExcel from "@/common/ExportExcel.js";
export default {
	components: { maintainForm, selectCar },
	data() {
		return {
			data: [],
			CarList: [],
			loading: false,
			organList: apiUtil.getOrganList(),
			pageSize: 10,
			total: '',
			pageSizeOptions: ['10', '20', '30', '50', '100'],
			param: {
				current: 1,
				limit: 20,
				keyword: '',
				organId: apiUtil.getOrganId(''),
				date: null,
				carIds: []
			},
			columns: [
				{ title: '车牌号', dataIndex: 'carNumber', width: 100, align: 'center' },
				{ title: '所属单位', scopedSlots: { customRender: 'organId' }, width: 200 },
				{ title: '维护日期', dataIndex: 'date', width: 160, align: 'center' },
				{ title: '维护费用', dataIndex: 'money', width: 100, align: 'center' },
				{ title: '经办人', dataIndex: 'person', width: 100 },
				{ title: '类型', dataIndex: 'type', width: 100 },
				{ title: '维护原因', dataIndex: 'cause', width: 200 },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
			]
		};
	},

	mounted() {
		this.getMaintainList();
	},
	methods: {
		...apiUtil,
		// 获取维修记录
		getMaintainList() {
			this.loading = true;
			if (this.param.date && this.param.date != '') {
				this.param.date = this.param.date.format('YYYY-MM');
			}
			this.http
				.get('/platform/car/maintain/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 删除维护记录
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/car/maintain/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getMaintainList();
						});
					}
				});
			});
		},
		// 导出报表
		Exportex() {
			if (this.data.records && this.data.records.length > 0) {
				let date = '';
				if (this.param.date && this.param.date != '') {
					date = this.param.date;
				} else {
					date = '全部';
				}
				const filterData = this.data.records.map((item) => {
					return { '车牌号': item.carNumber, "维护日期": item.date, "维护费用": item.money, "维护类型": item.type, "维护原因": item.cause, "经办人": item.person, };
				});
				ExporttoExcel(filterData, `${date}维护记录表.xlsx`);
			} else {
				this.$message.info('没有数据哦！！!');
				return;
			}
		},
		// 筛选条件变化重新获取数据
		doRefresh() {
			// console.log(111);
			this.param.current = 1;
			this.getMaintainList();
		},

		// 筛选单位
		filterOrgan(e) {
			this.param.organId = e;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getMaintainList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getMaintainList();
		}
	}
};
</script>

<style></style>
